/* eslint-disable react/no-unknown-property */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-dupe-else-if */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */
/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-empty-interface */
// import { NextPage, GetStaticPaths } from "next";
import React from "react";
// import { useRef } from "react";
// import { SitePluginFieldsEnum } from "../../../generated/graphql-types";

// export const getStaticProps: GetStaticProps = async () => {};

// export const getStaticPaths: GetStaticPaths = async (s: any) => {
//   console.log("TESTing");
//   console.log(s);
// };

// export const TypePage: NextPage<TypePageProps> = (type) => {
//   let test = "";
//   test = type;
//   console.log(test);
//   return test.slug;
// };
// export default TypePage;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { graphql, PageProps } from "gatsby";
import {
  Article,
  Expander,
  Hero,
  HillsAndValleysCarousel,
  HillsAndValleysCarouselContent,
  Infographic,
  Quote,
  Stories,
  ValuesCollection,
} from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
// import { Query } from "../../../generated/graphql-types";
import inline from "../../images/pipwareheading.jpg";
import ticimg from "../../images/tick_pattern.svg";
import {
  CleanedRTF,
  GenericRichTextNode,
  getCleanedRTF,
} from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import { GA_HillsAndValleysCarouselContent } from "../../components/GA_HillsAndValleysCarouselContent";
import { getImage } from "gatsby-plugin-image";
import { getProfileInformation } from "../../utils";
import { Ga_pipwareCardModal } from "../../components/GA_pipwareCardModal";
import { RTF } from "../../elements";
import { Node } from "@graphcms/rich-text-types";
import { Helmet } from "@bond-london/gatsby-plugin-cms-i18next";

interface CaseStudy {
  link: string;
  title: string;
  strong: string;
}
const GA_PRESS: React.FC<
  PageProps<
    Pick<
      Query,
      "graphCmsSectorPage" | "allGraphCmsSectorPage" | "allGraphCmsGaPipware"
    >
  >
> = ({
  path,
  URL,
  data: {
    allGraphCmsGaPipware: { nodes },
    graphCmsSectorPage: sectorPage,
    allGraphCmsSectorPage: { edges },
  },
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  console.log("URL");
  console.log(URL);
  let title;
  let heading;
  let heroimage;
  let statement;
  let sideHeading;
  let callouts;
  let quotes;
  let successImage;
  let successStories;
  let referencelink;
  let referancLable;
  let persondetails;
  let stories: { link: string; title: string; strong: string }[];
  let infographic;
  let count = 0;
  let collection;
  let pageLinksvalue;
  let cardBlockTitle;
  let cardModalLable;
  let cardModalContent;
  let cardModalLink;
  let benefits = {};
  let benefitsDescription;
  let benefitsHeading: CleanedRTF | undefined;
  let glanceHeading: GenericRichTextNode | undefined;
  let glancePackages: any[];
  const glanceKey: string[] = [];

  const caseStudies: CaseStudy[] = [];
  const inlineImage1 = {
    layout: "constrained",
    backgroundColor: "#282828",
    images: {
      fallback: {
        sizes: "(min-width: 1280px) 1280px, 100vw",
        src: { inline },
        srcSet: inline,
        sources: [],
        layout: "constrained",
        width: 1280,
      },
    },
    width: 1280,
    height: 720,
  };

  function settingImage(_url: any) {
    let inlineImage = {
      layout: "constrained",
      backgroundColor: "#282828",
      images: {
        fallback: {
          sizes: "(min-width: 1280px) 1280px, 100vw",
          src: { _url },
          srcSet: _url,
          sources: [],
          layout: "constrained",
          width: 1280,
        },
      },
      width: 1280,
      height: 720,
    };
    return inlineImage;
  }
  function setQuotaionImage(values) {
    let quoation_image = {
      company: null,
      country: values.country,
      image: {
        layout: "constrained",
        backgroundColor: "#0898a8",
        images: {
          fallback: {
            sizes: "(min-width: 600px) 600px, 100vw",
            src: values.image.url,
            srcSet: values.image.url,
            sources: [],
            layout: "constrained",
            width: 600,
          },
        },
        width: 600,
        height: 600,
      },
      jobTitle: values.jobTitle,
      link: "/people/skipp-williamson-et",
      linkedin: "https://www.linkedin.com/in/skippwilliamson/",
      name: values.name,
      quote:
        "In 30 years of consulting, I have not seen a change that will be as transformative and as positive for most of our clients’ economics as energy transition. We first started working on energy efficiency 20 years ago, but the last five years have created exponentially accelerating changes in the pace of requirements and reductions in costs curves. Great opportunities are opening for those who frame the problems well and act fast.",
      sector: "Infrastructure",
    };
    return quoation_image;
  }

  nodes?.map((values) => {
    if (values.pageUrl == URL && count == 0) {
      title = values.title;
      heading = values.heading;
      heroimage = values.heroImage.url;
      statement = values.statement;
      sideHeading = values.sideHeading;
      callouts = values.callouts;
      quotes = values.quotes;
      quotes = getCleanedRTF(values.quotes);
      successImage = values.successImage.url;
      successStories = values.caseStudy;
      referencelink = values.referenceLink;
      referancLable = values.referenceLable;
      persondetails = setQuotaionImage(values.person);
      collection = values.pageLinkCollection;
      cardBlockTitle = values.cardBlockTitle;
      cardModalContent = values.cardModalContent;
      cardModalLable = values.cardModalLable;
      cardModalLink = values.cardModalLink;
      benefits = values.benefits;
      benefitsDescription = values.benefitsDescription;
      benefitsHeading = getCleanedRTF(values.benefitsHeading);
      // console.log("benefits");
      // console.log(getCleanedRTF(benefitsHeading));
      // benefitsHeading = getCleanedRTF(benefitsHeading);
      glanceHeading = values.glanceHeading;
      // glanceHeading = getCleanedRTF(glanceHeading);
      glancePackages = values.glancePackages;
      for (const item of values.caseStudy) {
        caseStudies.push({
          link: "/case-study/" + item.slug,
          title: item.headline,
          strong: "",
        });
      }
      infographic = {
        title: values.infographicTitle,
        isTitleVisible: "",
        details: values.details,
        message: "sdfds",
        background: "GradientLightToDarkViaDark",
        animation: "",
      };
      count += 1;
    }
  });
  if (!nodes) return null;
  glancePackages?.map((packvalue) => {
    return packvalue.packages?.map((insidevalue) => {
      for (const key in insidevalue) {
        if (insidevalue.hasOwnProperty(key)) {
          const value = insidevalue[key];
          glanceKey.push(key);
        }
      }
    });
  });
  let uniqueglanceArray: string[] = [...new Set(glanceKey)];
  uniqueglanceArray.sort();
  uniqueglanceArray = [
    "Core features (dashboards, import/export, APIs, notifications,reporting)",
    "Action tracking",
    "Key Performance Indicators",
    "Value Hound and Analysis",
    "Results Actions Review",
    "Meetings",
    "Initiative management",
    "Work plan management",
    "Risk management",
    "Stage-gated approval workflows",
    "Automated benefit calculation",
    "Portfolio Management",
  ];
  // window.onload = function () {
  //   benefits?.map((s: any, index) => {
  //     const message = s.description;
  //     let outputElement = document.getElementById("des" + index);
  //     if (outputElement) {
  //       outputElement.innerHTML = message;
  //     } else {
  //       console.error("Output element not found.");
  //     }
  //   });
  //   const glancemessage = glanceHeading;
  //   let outputElement1 = document.getElementById("glance");
  //   if (outputElement1) {
  //     outputElement1.innerHTML = glancemessage;
  //   } else {
  //     console.error("Output element not found.");
  //   }
  //   const benefitsHeadingmessage = benefitsHeading;
  //   let outputElement2 = document.getElementById("Benifits");
  //   if (outputElement2) {
  //     outputElement2.innerHTML = benefitsHeadingmessage;
  //   } else {
  //     console.error("Output element not found.");
  //   }
  // };

  let childpagelinks: any[] = [];
  // console.log(quotes);
  // if (count == 0) return null;
  // if (count >= 1) {
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "WiredUp, Partners in Performance, Business Transformation, Initiative Management, Project Management, Emissions Management, Digital Transformation"
      }
      title={heading + " | Partners in Performance"}
      description={
        "WiredUp is a proven change management software suite that makes managing successful programs simple, easy and secure. "
      }
    >
      {count == 0 && (
        <Hero
          title="We could not find the page you are looking for"
          short={false}
          className="bg-dark-blue"
          showSearch={true}
        />
      )}
      {count >= 1 && (
        <>
          <Hero
            title={heading}
            image={settingImage(heroimage)}
            // colour="MediumBlue"
            short={true}
            treatment={sectorPage?.heroTreatment}
            showSearch={true}
          />
          {/* {console.log(getCleanedRTF(sideHeading))} */}
          {console.log(getCleanedRTF(statement))}
          <Expander
            paragraphsRTF={getCleanedRTF(statement)}
            introduction={
              <RTF
                content={getCleanedRTF(sideHeading)}
                className="col-start-2 col-span-6 text-right-margin mt-20px space-y-20px"
              />
            }
            buttonlable=""
            buttonlink="#"
            callouts={callouts?.map((c) => ({
              title: c.headline,
              contentRTF: getCleanedRTF(c.content),
            }))}
          />
          <Quote
            quote={
              <RTF
                className={classNames("text-right-margin")}
                contents={quotes} // content={[]}
                content={quotes[0]["children"]}
              />
            }
            profile={persondetails}
            withoutqote="YES"
          />
          <Infographic
            key={infographic.id}
            title={infographic.title}
            isTitleVisible={infographic.isTitleVisible}
            headlineRTF={getCleanedRTF(infographic.message)}
            statistics={infographic.details as StatisticDetails[]}
            backgroundColour={"LightBlue"}
            dotsAnimation="White"
            // fromwhere="pipware"
          />
          <section
            data-componet="tets"
            className="relative z-10 ga-pipwarecustomsection mt-30px"
          >
            <p className="" id="Benifits">
              {benefitsHeading && (
                <RTF
                  // className={classNames(
                  //   "text-right-margin h2 ga-benefit leading-tight mt-30px"
                  // )}
                  content={benefitsHeading} // content={[]}
                  // content={[]}
                />
              )}
            </p>
            <br></br>
            {/* md:w-mobile-12-cols lg:w-mobile-11-cols */}
            <div className="p-10px  w-auto lg:grid-cols-4 md:w-mobile-12-cols lg:w-mobile-11-cols md:grid-cols-1 grid-cols-1 p-10px page-grid row">
              {benefits?.map((s: any, index) => {
                const sentence = s.description;
                // const modifiedSentence = sentence.replace(
                //   /Wired/g,
                //   "<strong>Wired</strong>"
                // );
                const message = s.description;

                let outputElement = document.getElementById("des" + index);
                if (outputElement) {
                  outputElement.innerHTML = message;
                } else {
                  console.error("Output element not found.");
                }
                return (
                  <div className="ga-wireupglance-header-bg mt-10px mb-10px">
                    <div className="ga-wireupglance-header-bg text-white-1 p-20px ">
                      <h2 className="h4 pl-10px text-left fontsize-wiredup-glance h-30px">
                        {s.heading}
                      </h2>
                    </div>
                    <div className="m-auto h-auto p-20px pt-5px ga-wireupglance-header-bg text-white-1">
                      <p className="p-10px " id={"des" + index}>
                        {message}
                      </p>
                    </div>
                  </div>
                );
              })}
              {benefits?.map((s: any, index) => {
                const message = s.description;
                let outputElement = document.getElementById("des" + index);
                if (outputElement) {
                  outputElement.innerHTML = message;
                } else {
                  console.error("Output element not found.");
                }
                // return (
                //   <div className="bg-white-1 mt-10px mb-10px">
                //     <div className="h-100px bg-dark-blue text-white-1 p-20px ">
                //       <h2 className="h4 p-10px ">{s.heading}</h2>
                //     </div>
                //     <div className="mt-15px h-300px ">
                //       <p className="p-10px " id={"des" + index}>
                //         {s.description}
                //       </p>
                //     </div>
                //   </div>
                // );
              })}
            </div>
          </section>
          <HillsAndValleysCarousel
            key={collection.id}
            id={collection.id}
            title={collection.showName ? collection.name : undefined}
            contents={collection.pageLinks?.map((s, index) => {
              const image = inlineImage1;
              childpagelinks.push(s.externallinks);
              return (
                <GA_HillsAndValleysCarouselContent
                  id={s.id}
                  index={index}
                  key={s.id}
                  image={settingImage(s.optionalImage.url)}
                  alt={"Image"}
                  title={s.optionalTitle}
                  messageRTF={getCleanedRTF(s.message)}
                  link={
                    s.sectorPage?.slug == null
                      ? s.externallinks != null
                        ? s.externallinks
                        : "#"
                      : "/service/" + s.sectorPage.slug
                  }
                  highlightName={s.highlightName}
                  narrowImage={s.narrowImage}
                />
              );
            })}
          />
          <section
            data-componet="tets"
            className="relative max-width z-10 ga-pipwarecustomsection"
          >
            <p className="" id="glance">
              {" "}
              {/* {glanceHeading} */}
              {
                <RTF
                  // className={classNames(
                  //   "text-right-margin ga-benefit leading-tight"
                  // )}
                  content={getCleanedRTF(glanceHeading)} // content={[]}
                />
              }
            </p>
            <br></br>
            <div className="p-10px xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 p-10px page-grid row">
              {glancePackages?.map((glancevalue, index) => {
                let values1 = glancevalue.packages[0];
                return (
                  // <div className="ga-wireupglance-bg mt-10px mb-10px">
                  <div className="mt-10px mb-10px">
                    <div className="m-auto h-auto p-20px">
                      <a href={"/en" + childpagelinks[index]}>
                        <h2 className="h4 p-10px">{glancevalue.Heading}</h2>
                        {/* <div className="height75">
                          <p
                            className="pl-10px pr-10px pt-10px "
                            id={"des" + index}
                          >
                            {glancevalue.Description}
                          </p>
                        </div> */}
                      </a>
                      {/* <hr className="hr-design"></hr> */}
                      <div className="h-auto">
                        <h5 className="h5 p-10px">
                          {glancevalue.includes && "Includes:"}
                        </h5>
                        {glancevalue.includes.map((includevalues: any) => {
                          return (
                            <div className="m-10px">
                              <tr>
                                {/* <td className="bg-left bg-no-repeat bg-28px min-h-28px w-30px h-30px"> */}
                                <td className="w-35px">
                                  <img
                                    src={ticimg}
                                    className="ticpositions"
                                  ></img>
                                  {/* <div className="stories-tic-pattern bg-left bg-no-repeat bg-28px min-h-28px w-30px"></div> */}
                                </td>
                                <td>
                                  <div className="h5">
                                    {includevalues.heading}
                                    {includevalues.Content && " - "}
                                    <span className="font-normal">
                                      {includevalues.Content}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </div>
                          );
                        })}
                      </div>
                      <div className="h-auto p-10px">
                        <h5 className="h5">
                          {glancevalue.add_on && "Optional add-on:"}
                        </h5>
                        {glancevalue.add_on &&
                          glancevalue.add_on.map((includevalues: any) => {
                            return (
                              <div className="m-10px">
                                <tr>
                                  {/* <td className="bg-left bg-no-repeat bg-28px min-h-28px w-30px h-30px"> */}
                                  <td className="w-35px">
                                    <img
                                      src={ticimg}
                                      className="ticpositions"
                                    ></img>
                                    {/* <div className="stories-tic-pattern bg-left bg-no-repeat bg-28px min-h-28px w-30px"></div> */}
                                  </td>
                                  <td>
                                    <div className="h5">
                                      <a href={includevalues.URL}>
                                        {includevalues.heading}
                                      </a>
                                      {includevalues.Content && " - "}
                                      <span className="font-normal">
                                        {includevalues.Content}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <center>
              <div className="ga-wireupglance-button-bg">
                <a href={"/en/wiredup-enquiry/"}>Request a demo</a>
              </div>
            </center>
            {/* <div className="overflow-x-auto">
              <table className="">
                <thead>
                  <tr>
                    <td colSpan={1}></td>

                    {glancePackages?.map((glancevalue) => {
                      return (
                        <td className="bg-dark-blue h-30px p-20px text-white-1 border-2">
                          {" "}
                          {glancevalue.Heading}
                        </td>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr className="ga-benifit-boder-bottom">
                    <td colSpan={1}></td>
                    {glancePackages?.map((glancevalue) => {
                      return (
                        <td className=" h-30px p-20px ">
                          {" "}
                          {glancevalue.Description}
                        </td>
                      );
                    })}
                  </tr>
                  {uniqueglanceArray?.map((value) => {
                    return (
                      <tr className="ga-benifit-boder-bottom">
                        <td className="p-10px">{value}</td>
                        {glancePackages?.map((glancevalue) => {
                          const packageValue = glancevalue.packages[0][value];
                          return (
                            <td className="text-center p-10px">
                              {packageValue === "YES"
                                ? String.fromCharCode(10004)
                                : packageValue}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
          </section>
          <Stories
            title={"Client success stories"}
            image={settingImage(successImage)}
            stories={caseStudies}
            classvalue="col-span-12 col-start-2 h7 text-center"
            // imagevalue="stories-tic-pattern"
          />
          <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
            Find out how WiredUp can help your organisation make a lasting
            impact{" "}
            <b>
              <br></br>
              <center>
                <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
                  Schedule a demo
                </a>
              </center>
            </b>
          </p>
          {/* <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
            To see what {referancLable} can do for your business,{" "}
            <b>
              <a href={referencelink} className="text-dark-blue">
                book a demonstration
              </a>{" "}
            </b>
            now
          </p> */}
          <br></br>
        </>
      )}
    </Layout>
  );
  // }
  // if (count == 0) {
  //   return (
  //     <Layout pagePath="/" title="404">
  //       <Hero
  //         title="We could not find the page you are looking for"
  //         short={false}
  //         className="bg-dark-blue"
  //         showSearch={true}
  //       />
  //     </Layout>
  //   );
  // }
};

export default GA_PRESS;

export const IndexQuery = graphql`
  {
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsSectorPage {
      edges {
        node {
          sector
          heroImage {
            id
            url
          }
          pageTitle
        }
      }
    }
    allGraphCmsGaPipware {
      nodes {
        title
        heading
        sideHeading {
          cleaned
        }
        pageUrl
        statement {
          cleaned
        }
        quotes {
          cleaned
        }
        person {
          name
          image {
            url
          }
          jobTitle
          country
        }
        heroImage {
          fileName
          mimeType
          width
          height
          size
          url
        }
        herobackgroundcolor
        callouts {
          id
          stage
          updatedAt
          headline
          content {
            cleaned
          }
        }
        caseStudy {
          stage
          headline
          slug
          pageLink {
            id
            optionalImage {
              url
            }
            optionalTitle
          }
        }
        successImage {
          id
          stage
          updatedAt
          handle
          fileName
          mimeType
          width
          height
          size
          url
        }
        pageLinkCollection {
          id
          name
          showName
          showNameInContent
          carouselType
          pageLinks {
            __typename
            id
            narrowImage
            optionalTitle
            message {
              cleaned
            }
            externallinks
            optionalImage {
              mimeType
              remoteId
              alternateText
              id
              linkUrl
              url
              localFile {
                id
              }
            }
            sectorPage {
              slug
            }
          }
        }
        referenceLable
        referenceLink
        details
        infographicTitle
        benefits
        benefitsHeading {
          cleaned
        }
        glanceHeading {
          cleaned
        }
        glancePackages
      }
    }
  }
`;
